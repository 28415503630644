.passwordDiv {
  position: relative;
}

.passwordInputField {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid black;
}
.visibility {
  position: absolute;
  right: -10px;
  z-index: 100;
  top: 10px;
}
