.middleStats {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 100px;
  background-color: var(--dark-blue);
  color: white;
  padding: 50px 0;
  margin-top: 80px;
  z-index: 9 !important;
  position: relative;
}
.middleStats .stat {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.middleStats .stat h3 {
  font-size: 1.5rem;
}

.middleStats .stat p {
  opacity: 0.7;
  width: 70%;
  margin: auto;
}

.middleStats span {
  height: 100px;
  width: 1px;
  background-color: white;
  color: white;
  opacity: 0.3;
}
