.modal {
  position: fixed;
  width: 50%;
  border: 5px solid var(--dark-blue);
  background-color: white;
  z-index: 99999;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  h1 {
    color: var(--dark-blue);
    text-align: center;
    font-size: 3rem;
    margin: 30px 0;
  }
}

#modalLink {
  background-color: var(--green);
  color: white;
  padding: 10px;
  border-radius: 10px;
  font-weight: 600;
}

.modalBuy {
  position: fixed;
  top: 50%;
  right: 22%;
  width: 50%;
  border: 5px solid var(--dark-blue);
  background-color: white;
  z-index: 99;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    color: var(--dark-blue);
    text-align: center;
    font-size: 3rem;
    margin: 30px 0;
  }
  div {
    width: 50%;
    margin: 5px auto;
    text-align: left;
    display: flex;
    justify-content: center;
  }
  label {
    width: 200px;
    font-weight: 600;
  }
  button {
    margin-top: 30px;
    width: 100px;
  }
}
