.register h1 {
  text-align: center;
  text-transform: uppercase;
}

.registerForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: auto;
  gap: 30px;
}
.registerForm > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.registerForm input,
label {
  width: 100%;
}

.registerForm input {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid black;
}

.registerForm a {
  color: var(--blue);
  text-decoration: none;
}
