.infoWrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 10%;
  background-color: white;
}

.infoWrapper .infos {
  display: flex;
  gap: 20px;
}
.infoWrapper .infos .info {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.infoWrapper .infos span {
  height: 30px;
  width: 2px;
  background-color: black;
  opacity: 0.3;
}

@media only screen and (max-width: 769px) {
  .infoWrapper {
    background-color: #5252794d;
    color: white;
    justify-content: center;
    padding: 10px;
  }
}
