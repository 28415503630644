.errorMessage {
  background-color: rgb(155, 0, 0);
  color: white;
  font-weight: 600;
  text-align: center;
  padding: 10px;
  position: fixed;
  top: 0px;
  right: 28%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
