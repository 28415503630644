.hero {
  position: relative;
  background-color: var(--dark-blue);
  overflow: hidden;
  padding: 100px 0;
}
#background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.15;
}

.hero #footballer {
  position: absolute;
  right: -50px;
  bottom: 0px;
  width: 35%;
  z-index: 2;
  opacity: 0.3;
}

.text {
  text-align: center;
  color: white;
  opacity: 1 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}
.text h1 {
  text-transform: uppercase;
  opacity: 1 !important;
  z-index: 9999;
  position: relative;
  font-size: 4rem;
  font-family: "League Gothic";
}
.text p {
  width: 60%;
  margin: 50px auto;
}

.text button {
  padding: 10px 20px;
  background-color: var(--light-blue);
  color: white;

  border: none;
  border-radius: 10px;
  font-size: 1rem;
}

@media only screen and (max-width: 769px) {
  .hero {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .text button {
    width: 90%;
    z-index: 9;
  }
  .hero #footballer {
    width: 80%;
  }
}
