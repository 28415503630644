.wrapper {
  width: 1000px;
  margin: 50px auto;
}
.headline {
  color: var(--dark-blue);
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 50px 0;
}
.accordionWrapper {
  & + * {
    margin-top: 1em;
  }
}

.accordionItem {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
  color: var(--dark-blue);
}

.accordionItem.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordionTitle {
  font-weight: 600;
  cursor: pointer;
  color: var(--light-blue-1);
  padding: 0.5em 1.5em;
  border: solid 1px var(--dark-blue);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordionTitle::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid currentColor;
}

.accordionTitle.open {
  color: var(--dark-blue) !important;
}

.accordionTitle.open::after {
  content: "";
  border-top: 0;
  border-bottom: 5px solid;
}
.accordionContent {
  padding: 1em 1.5em;
}
@media only screen and (max-width: 480px) {
  .wrapper {
    width: 90%;
  }
}
