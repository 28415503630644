a {
  color: unset;
  text-decoration: none;
}

/* active tips */
.activeTips {
  width: 90%;
  margin: 50px auto;
  text-align: center;
  background-color: var(--blue);
  padding: 10px 20px;
  border-radius: 20px;
  color: white !important;
}

.activeTips .tipHeading {
  text-align: center;
  color: var(--light);
  text-transform: uppercase;
  margin: 20px 0;
}
.activeTips .tipsList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.lockedTip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--blue-2) !important;
  padding: 10px 30px;
  border-radius: 10px;
  width: 100%;
}
.lockedTip .left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
}
.lockedTip .left p {
  height: fit-content;
}
.lockedTip #buy {
  padding: 14px 50px;
  border-radius: 10px;
  border: none;
  background-color: var(--light-blue);
  color: white;
  font-weight: 600;
  font-size: 1rem;
}
.lockedTip .left #lock {
  width: 50px;
  height: 50px;
}
.activeTips .tip {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--blue-2) !important;
  padding: 10px 30px;
  border-radius: 10px;
  text-align: left;
}
.activeTips .tip .infoWrap {
  display: flex;

  align-items: center;
  background-color: var(--blue-2) !important;

  border-radius: 10px;
  text-align: left;
  gap: 15px;
}

.activeTips .tip .data {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.activeTips .tip .data p {
  opacity: 0.7;
  text-transform: capitalize;
}
.activeTips .tip .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.activeTips .tip:hover {
  cursor: pointer;
  opacity: 0.95;
  transform: scale(1.01);
}

.activeTips .tip .tipHeader {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-weight: 600;
}
.activeTips .tip .right {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  color: var(--light-0);
}
.activeTips .tip .right .rightText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}
.activeTips .tip .right .rightText div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.activeTips .tip .right .rightText .time {
  opacity: 0.5;
  font-size: 0.9rem;
}
.activeTips .tip .right div img {
  width: 20px;
}
.activeTips .tip .sportBall {
  width: 80px;
}

.paginationBtn {
  padding: 15px;
  width: 50px;
  border-radius: 10px;
  background-color: var(--dark-blue);
  border: none;
  color: var(--light-0);
  font-weight: 600;
  font-size: 1rem;
  transition: 0.3s;
}
.paginationBtn:hover {
  background-color: var(--purple-gray);
  cursor: pointer;
}

.pagination {
  display: flex;
  gap: 10px;
  justify-content: center;
}
.last {
  background-color: #3c3c69;
  padding: 10px 20px;
  border-radius: 30px;
  color: rgba(20, 230, 20, 0.911);
}
.last.loss {
  color: rgb(255, 49, 26);
}

@media only screen and (max-width: 769px) {
  .activeTips .tip {
    flex-direction: column;
    position: relative;
    border-radius: 30px;

    gap: 20px;
    flex-grow: 1;
  }
  .activeTips .tip .right {
    width: 100%;
    flex-grow: 1;
  }
  .activeTips .tip .infoWrap {
    justify-content: space-between;
    width: 100%;
    flex-grow: 1;
  }
  .last {
    position: absolute;
    top: 15px;
    right: 10px;
  }

  #first {
    order: 2;
    justify-content: flex-start;

    #tipster h3 {
      display: none;
    }
    #roi {
      flex-direction: row;
      color: #0bf1ff;
      font-size: 0.9rem;

      align-items: center;
    }
    #roi h3 {
      font-weight: 400;
    }
  }
}
