.login h1 {
  text-align: center;
  text-transform: uppercase;
}

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: auto;
  gap: 30px;
}
.loginForm > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.loginForm input,
label {
  width: 100%;
}

.loginForm input {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid black;
}

.loginForm a {
  color: var(--blue);
  text-decoration: none;
}
