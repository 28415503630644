.membershipWrapper {
  background-color: var(--blue);
  padding: 50px;
  text-align: center;
  color: white;
}

.membershipWrapper #headline {
  text-transform: uppercase;
  font-family: "League Gothic";
  font-size: 4rem;
}

.membershipWrapper #subheadline {
  color: #0bf1ff;
  margin: 30px 0;
}

.membershipWrapper .packages {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.membershipWrapper .packages .package {
  background-color: #262658;
  border-radius: 30px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
}
.membershipWrapper .packages .package.packageDark {
  border: 1px solid rgba(81, 134, 250, 0.21);
  background-color: var(--blue) !important;
}

.membershipWrapper .packages .package .header {
  display: flex;
  justify-content: space-between;
}
.membershipWrapper .packages .package .header h3 {
  font-family: "Montserrat" !important;
  font-weight: 700 !important;
  font-size: 1.5rem;
}

.membershipWrapper .packages .package .description {
  text-align: left;
  margin: 30px 0;
}

.membershipWrapper .packages .package .description .descriptionLine {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.membershipWrapper .packages .package button {
  padding: 10px 30px;
  border-radius: 50px;
  border: none;
  color: #1c3f84;
  transition: 0.3s;
}
.membershipWrapper .packages .package.packageDark button {
  background: #262658;
  color: white;
}
.membershipWrapper .packages .package button span {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0 5px;
}

.membershipWrapper .packages .package button:hover {
  transform: scale(1.05);
  background-color: rgb(230, 230, 230);
  cursor: pointer;
}

.membershipWrapper .packages .package .paymentMethods {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  gap: 20px;
}

@media only screen and (max-width: 769px) {
  .membershipWrapper {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .membershipWrapper .packages {
    flex-direction: column;
  }
}
