.unique {
  background-color: var(--middle-blue);
  color: white;
  text-align: center;
  padding: 50px 0;
}
.unique .points {
  width: 80%;
  margin: 100px auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.unique h1 {
  font-family: "League Gothic";
  font-size: 4rem;
  margin: 50px 0;
  text-transform: uppercase;
}

@media only screen and (max-width: 769px) {
  .unique {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .unique .points {
    flex-direction: column;
    gap: 100px;
  }
  .unique .points div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
}
