.statsWrapper {
  width: 95%;
  margin: 50px auto;
}
.tipsterWrap {
  background-color: var(--blue);
  padding: 50px;
  border-radius: 50px;
}
.heading {
  color: white;
  padding: 50px;
}
.heading h1 {
  margin-bottom: 50px;
  font-size: 3rem;
  font-weight: 400;
  font-family: "League Gothic";
  text-transform: uppercase;
  letter-spacing: 4px;
}
.tipsterList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.tipster {
  display: flex;
  flex-grow: 1;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--blue-2) !important;

  padding: 15px 50px;
  border-radius: 10px;

  color: white;
}
.tipsterHeading {
  text-align: center;
  color: white;
  margin-top: 30px;
  font-size: 3rem;
}
.tipster .wrap {
  display: flex;
  align-items: center;

  justify-content: space-evenly;
  flex-grow: 1;
}
.tipster img {
  width: 80px;
  border-radius: 10px;
}
.tipster .left {
  display: flex;
  align-items: center;
  gap: 20px;
}
.tipster .left .rightText {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 10px;

  p {
    opacity: 0.8;
    font-size: 0.85;
  }
}
.tipster .data {
  display: flex;
  flex-direction: column;
  gap: 10px;

  h3 {
    font-weight: 400;
  }
  p {
    opacity: 0.7;
  }
  .innerData {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 5px 0;

    font-weight: 500;
    p {
      opacity: 1;
    }
    #profit {
      color: rgb(91, 238, 91);
    }
  }
}

.tipster button {
  padding: 10px 20px;
  border-radius: 40px;
  border: none;
  background-color: #343475;
  color: #1be3dd;
  font-weight: 600;
}

@media only screen and (max-width: 769px) {
  .tipsterList {
    width: 90%;
  }
  .tipster {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: relative;
    padding-bottom: 40px;
  }
  .tipster .wrap {
    width: 100%;
  }

  .tipster .wrap #last {
    position: absolute;
    bottom: 0;
    flex-direction: row;
  }
  .tipster button {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
