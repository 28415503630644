.tipsterInfo {
  width: 80%;
  margin: 50px auto;
}
.tipster {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  color: white;

  img {
    width: 100px;
  }

  .rightText {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }
  .rightText #sport {
    padding: 5px;
    background-color: var(--blue);
    opacity: 0.8;
    border-radius: 10px;
  }
}

.info {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  color: white;
  margin: 50px 0;
}

.info > div {
  border-right: 1px solid white;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}

.info > div p {
  opacity: 0.8;
}

#chart {
  width: 80%;
  margin: auto;
}
@media only screen and (max-width: 769px) {
  .info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 30px;
  }
}

@media only screen and (max-width: 500px) {
  .info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 30px;
  }
}
