.navbar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.wrapperNav {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 50px 10% 30px 10%;
  background-color: var(--dark-blue);
}
.navbar #logo {
  width: 200px;
}

.wrapperNav a {
  color: var(--light);
  text-decoration: none;
  font-weight: 600;
}
.navbar .links {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  gap: 40px;
}

.navbar a:hover {
  transform: scale(1.05);
  color: var(--light-0);
}
.close {
  display: none !important;
}

#btns {
  background-color: var(--light-blue);
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 0 10px;
}

@media only screen and (max-width: 769px) {
  .navbar {
    position: relative;
    align-items: center;
  }
  .navbar .logoWrap {
    order: 1;
  }

  .navbar .links {
    display: none;
  }

  .btns {
    order: 2;
  }
  .btns #btns {
    background-color: #1e1e40;
    font-weight: 400;
  }
  .dropdownHidden {
    display: none !important;
  }

  .linksWrap {
    order: 3;
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;

    transition: 0.3s;
  }
  .hamburger > div {
    width: 40px;
    height: 5px;
    background-color: white;
    z-index: 9999;
    border-radius: 50px;
  }
  .close {
    display: block !important;
    position: absolute;
    font-weight: 600;
    color: white;
    bottom: -80px;
    right: 0px;
    font-size: 1.5rem;
    background-color: #1e1e40;
    padding: 5px;
    text-align: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    transition: all 0.3s ease;
  }
  .close:hover {
    cursor: pointer;
  }
  .hamburgerLinks {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    border-top: 1px solid white;
    padding-top: 30px;
  }
}
