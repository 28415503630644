.tipDetails {
  width: 80%;
  margin: 50px auto;
  border: 1px solid var(--dark-blue);
  border-radius: 10px;
  box-shadow: 0px 0px 26px 4px rgba(2, 82, 116, 0.67);
  padding: 20px;
  color: white;
}

.tipDetails .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid white;
  color: white;
}

.tipDetails .heading div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.tipDetails .heading img {
  width: 50px;
}
.tipDetails .heading p {
  font-weight: 600;
}

.tipDetails .body {
  margin: 50px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.tipDetails .body .tipData {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid var(--dark-blue);
  padding: 10px;
  border-radius: 10px;
}
.tipDetails .body .tipData input {
  padding: 10px;
  border: none;
  background-color: var(--dark-blue);
  border-radius: 10px;
  border: 1px solid white;
  color: white;
  font-weight: 600;
  width: 100%;
}

.tipDetails .body .tipData .middleRow {
  display: flex;
  gap: 10px;
}

.tipDetails .body .tipData .middleRow > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.tipDetails .body .analysis {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
}
.tipDetails .body .analysis textarea {
  width: 100%;
  border: none;
  background-color: var(--dark-blue);
  border-radius: 10px;
  padding: 10px;
  resize: none;
  color: white;
  font-size: 1rem;
  font-weight: 500;
}

@media only screen and (max-width: 769px) {
  .tipDetails .heading {
    gap: 20px;
    align-items: flex-start;
    flex-direction: column;
  }
  .tipDetails .body {
    grid-template-columns: repeat(1, 1fr);
  }
  .tipDetails .body .tipData .middleRow {
    flex-direction: column;
  }
}
