/* GENERAL CSS FOR ENTIRE APP */
@import url("https://fonts.googleapis.com/css2?family=League+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=League+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  background-color: var(--dark-blue);
}
:root {
  --dark-blue: #000036;
  --middle-blue: #0a0a40;
  --blue: #14144a;
  --blue-2: #1e1e54;

  --light-blue: #0066ff;

  --purple-gray: #464866;
  --light: #aaabb8;
  --light-0: #fbfbfb;

  --light-blue-1: #29648a;
  --green: rgb(0, 128, 92);
}

#root {
  width: 100% !important;
  height: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
  overflow-x: hidden !important;
}

.standard-btn {
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: var(--dark-blue);
  color: white;
  font-weight: 600;
  text-align: center;
}
.standard-btn:hover {
  transform: scale(1.03);
  filter: brightness(1.5);
  cursor: pointer;
}
#topMsg {
  text-align: center;
  color: var(--dark-blue);
  font-weight: 600;
  margin-bottom: 80px;
}
#bottomMsg {
  text-align: left;
  color: white;
  width: 64%;
  margin-top: 40px;
  margin-bottom: 10px;
  margin-left: 30px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

#bottomMsg img {
  width: 50px;
}

#successMsg {
  background-color: var(--green);
  color: white;
  font-weight: 600;
  text-align: center;
  padding: 10px;
  position: fixed;
  top: 0px;
  right: 35%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

@media only screen and (max-width: 480px) {
  #successMsg {
    right: 0;
    width: 100%;
  }
}
